import { gsap } from 'gsap'
import Cookies from 'js-cookie'

export class Loading {
  constructor() {
    const loading = document.getElementById('js-loading')
    if (loading === null) return

    const bg01 = loading.querySelector('.c-loading__bg.-no01')
    const bg02 = loading.querySelector('.c-loading__bg.-no02')
    const bg03 = loading.querySelector('.c-loading__bg.-no03')
    const kv = document.getElementById('js-index-kv')
    const fune = document.querySelector('.p-index_kv__title')
    const header = document.getElementById('js-header')
    const sp = loading.querySelector('.c-loading__sp')

    let isOpening = false
    const cookieName = 'uni-fukuya-recruit'
    const cookie = Cookies.get(cookieName)
    const documentBody = document.body
    const expires = new Date()
    expires.setTime(expires.getTime() + 1000 * 60 * 60)
    if (!cookie) {
      documentBody.classList.add('is-opening')
      isOpening = true
      Cookies.set(cookieName, '1', {
        expires,
        samesite: 'lax',
      })
    }

    const firstAnimation = () => {
      bg01.style.opacity = 1
      fune.style.opacity = 1
      const tl1 = gsap.timeline()
      tl1
        .to(bg02, { opacity: 1, ease: 'power1.in' }, '+=0.7')
        .to(bg01, { opacity: 0, ease: 'power1.in' }, '-=0.25')
        .to(bg03, { opacity: 1, ease: 'power1.in' }, '+=0.3')
        .to(bg02, { opacity: 0, ease: 'power1.in' }, '-=0.3')
      tl1.to(loading, {
        onStart: () => {
          loading.classList.add('is-start')
          kv.classList.add('loading-end')
        },
      })
      tl1.to(
        kv,
        {
          onStart: () => {
            kv.classList.add('kv-start')
            header.classList.add('kv-start')
          },
        },
        '>0.7'
      )
    }

    const firstSPAnimation = () => {
      const tl1sp = gsap.timeline()
      tl1sp.to(
        sp,
        {
          onStart: () => {
            sp.classList.add('is-start-makimono')
          },
        },
        '<1'
      )
      tl1sp.to(
        loading,
        {
          onStart: () => {
            loading.classList.add('is-start')
          },
        },
        '<1'
      )
    }

    const visitedAnimation = () => {
      loading.style.opacity = 0
      loading.style.display = 'none'
      loading.style.visibility = 'hidden'
      const tl2 = gsap.timeline()
      tl2.to(loading, {
        onStart: () => {
          kv.classList.add('is-cookie')
        },
      })
      tl2.to(
        kv,
        {
          onStart: () => {
            kv.classList.add('kv-start')
            kv.classList.add('kv-visit')
            header.classList.add('kv-start')
          },
        },
        '<'
      )
    }

    if (documentBody.classList.contains('is-opening')) {
      if (window.matchMedia('(max-width: 1023px)').matches) {
        firstSPAnimation()
      } else {
        firstAnimation()
      }
    } else {
      visitedAnimation()
    }
  }
}
