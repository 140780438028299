export class Validation {
  constructor() {
    jQuery(function ($) {
      // バリデーションメソッドの追加
      $.validator.addMethod(
        'furigana',
        function (value, element) {
          return this.optional(element) || /^[\u3040-\u309F\u30A0-\u30FFｧ-ﾝﾞﾟ]*$/.test(value)
        },
        'ひらがなもしくはカタカナで入力してください'
      )
      $.validator.addMethod(
        'phone',
        function (value, element) {
          var sanitizedValue = value.replace(/-/g, '')
          return this.optional(element) || /^\d{10,11}$/.test(sanitizedValue)
        },
        '電話番号の形式で入力してください'
      )
      // バリデーションの初期化
      $('.wpcf7-form').validate({
        rules: {
          'your-name': { required: true },
          'your-name-kana': { required: true, furigana: true },
          'your-zip': { required: true },
          'your-address01': { required: true },
          'your-tel': { required: true, phone: true },
          'your-email': { required: true, email: true },
          'your-year': { required: true },
          'your-month': { required: true },
          'your-day': { required: true },
          'your-works': { required: true },
          'your-academic': { required: true },
          'your-car': { required: true },
        },
        messages: {
          'your-name': {
            required: '入力必須項目です',
          },
          'your-name-kana': {
            required: '入力必須項目です',
          },
          'your-zip': {
            required: '入力必須項目です',
          },
          'your-address01': {
            required: '入力必須項目です',
          },
          'your-tel': {
            required: '入力必須項目です',
          },
          'your-email': {
            required: 'メールアドレスを入力してください',
            email: '有効なメールアドレスを入力してください',
          },
          'your-year': {
            required: '入力必須項目です',
          },
          'your-month': {
            required: '入力必須項目です',
          },
          'your-day': {
            required: '入力必須項目です',
          },
          'your-works': {
            required: '入力必須項目です',
          },
          'your-academic': {
            required: '入力必須項目です',
          },
          'your-car': {
            required: '入力必須項目です',
          },
        },
      })

      // 送信ボタンのクリックイベント
      $('.wpcf7-submit').on('click', function (e) {
        e.preventDefault() // 最初にイベントをキャンセル

        // フォームのバリデーションを手動でトリガー
        if ($('.wpcf7-form').valid()) {
          if (!window.confirm('本当に送信しますか？')) {
            // 送信をキャンセル
            return
          }
          $('.wpcf7-form').submit()
          setTimeout(() => {
            const currentPath = window.location.pathname
            if (currentPath.includes('/recruit/disabilities/entry/')) {
              location = '/recruit/disabilities/entry/complete/'
            } else {
              location = '/recruit/career/entry/complete/'
            }
          }, 100)
        } else {
          alert('入力内容に不備があります')
          $('html, body').animate(
            {
              scrollTop: $('.c-heading02').offset().top,
            },
            500
          )
        }
      })
    })
  }
}
