'use strict'

// import { IeModal } from './lib/IeModal'
// import { CookiePolicy } from './lib/CookiePolicy'
import { Svg } from './lib/Svg'
import { TelGrant } from './lib/TelGrant'
import { Smooth } from './lib/Smooth'
import { Drawer } from './lib/Drawer'
import { ScrollStatus } from './lib/ScrollStatus'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { ButtonMove } from './lib/ButtonMove'
import { Loading } from './lib/Loading'
import { flowSlider } from './lib/flowSlider'
import { FormFile } from './lib/FormFile'
import { ScrollControl } from './lib/ScrollControl'
// import { FormYear } from './lib/FormYear'
import { Validation } from './lib/Validation'

// new IeModal('サイト名', 'http://hoge.com')
// new CookiePolicy('optout')
new Svg()
new TelGrant()
new Smooth()
new Drawer()
new ScrollStatus()
new ScrollAnimation()
new ButtonMove()
new Loading()
new flowSlider()
new FormFile()
new ScrollControl()
// new FormYear()
new Validation()
