export class FormFile {
  constructor() {
    const sizeLimit = 10485760
    // const sizeLimit = 10240
    const files = document.querySelectorAll('.js-file')
    if (!files) return
    files.forEach((file) => {
      const input = file.querySelector('input')
      const text = file.querySelector('.js-file-text')
      input.addEventListener('change', (e) => {
        const fileList = input.files
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i].size > sizeLimit) {
            // ファイルサイズが制限以上
            alert('ファイルサイズは10MB以下にしてください')
            input.value = ''
            text.innerText = ''
            return
          } else {
            text.innerText = fileList[i].name
          }
        }
      })
      const fileDeltion = file.querySelector('.js-deletion')
      fileDeltion.addEventListener('click', function () {
        text.innerText = ''
        input.value = ''
      })
    })
  }
}
