import { gsap } from 'gsap'

/**
 * ドロワーメニューの開閉機能
 */
export class Drawer {
  constructor() {
    this.hamburger = document.getElementById('js-hamburger')
    this.drawer = document.getElementById('js-drawer')
    if (this.hamburger === null || this.drawer === null) return

    this.hamburger.addEventListener('click', () => {
      this.hamburger.querySelector('span').classList.toggle('hidden')
      this.hamburger.classList.toggle('is-act')

      if (this.hamburger.classList.contains('is-act')) {
        this.animation(this.drawer, 'open')
        this.drawer.style.visibility = 'visible'
        document.querySelector('body').classList.add('is-drawer')
      } else {
        this.animation(this.drawer, 'close')
        this.drawer.style.visibility = 'hidden'
        document.querySelector('body').classList.remove('is-drawer')
      }
    })
  }
  animation(el, type) {
    if (type == 'open') {
      gsap.to(el, 0.3, { autoAlpha: 1, display: 'block' })
    } else {
      gsap.to(el, 0.3, { autoAlpha: 0, display: 'none' })
    }
  }
}
