export class ScrollControl {
  constructor() {
    const ScrollArea = document.querySelector('.js-scroll-control')
    if (ScrollArea === null) return

    const MoveScroll = () => {
      window.addEventListener('load', () => {
        ScrollArea.scrollLeft = 1000
      })
    }

    MoveScroll()
  }
}
