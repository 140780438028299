import Swiper from 'swiper'
import { FreeMode, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/scrollbar'

export class flowSlider {
  constructor() {
    this.slider = document.querySelector('.js-flow-swiper')
    this.slider02 = document.querySelector('.js-sp-flow-swiper')

    if (this.slider === null && this.slider02 === null) return

    const mySwiper = new Swiper('.js-flow-swiper', {
      slidesPerView: 'auto',
      modules: [FreeMode, Scrollbar],
      freeMode: {
        enabled: true,
        momentumRatio: 1,
        momentumVelocityRatio: 2,
      },
      scrollbar: {
        el: '.js-scroll-bar',
        draggable: true,
      },
    })
    if (this.slider02 === null) return
    // タブレットからスライドに
    const swiperSlides = document.getElementsByClassName('js-sp-flow-swiper')
    const breakPoint = 1024
    let swiper
    let swiperBool
    window.addEventListener(
      'load',
      () => {
        if (breakPoint < window.innerWidth) {
          swiperBool = false
        } else {
          createSwiper()
          swiperBool = true
        }
      },
      false
    )
    window.addEventListener(
      'resize',
      () => {
        if (breakPoint < window.innerWidth && swiperBool) {
          swiper.destroy(false, true)
          swiperBool = false
        } else if (breakPoint >= window.innerWidth && !swiperBool) {
          createSwiper()
          swiperBool = true
        }
      },
      false
    )
    const createSwiper = () => {
      swiper = new Swiper('.js-sp-flow-swiper', {
        slidesPerView: 'auto',
      })
    }
  }
}
